.contact-form-container {
  width: 90%;
  max-width: 900px;
  margin-top: 1.2em;
}

.submit-btn {
  max-width: 200px;
  border-radius: 15px;
}

@media (max-width: 576px) {
  .contact-form-container {
    width: 100%;
  }
}
