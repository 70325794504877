.selected-work-img-figure {
  text-align: end;
}

.selected-work-img {
  width: 350px;
  border-radius: 20px;
  max-height: 200px;
}

.selected-work-description {
  width: 90%;
  max-width: 500px;
}

@media (max-width: 768px) {
  .selected-work-img-figure {
    text-align: center;
  }

  .selected-work-img {
    width: 300px;
  }

  .selected-work-description {
    width: 100%;
    max-width: none;
    text-align: center;
  }

  .selected-work-title {
    text-align: center;
  }

  .selected-work-icons {
    justify-content: center;
  }
}

